

.main_projects{
  padding: 10%;
}

.github{
  display: grid;
  gap: 2em;
  grid-template-columns: repeat(auto-fill, minmax(min(20rem, 100%), 1fr));
}

.card{
  display: flex;
  flex-flow: column ;
  justify-content: space-around;
  align-items: center;
  background-color: #000000;
  cursor: pointer;
  padding:.5em;
}

 .overlay{
  display:none;

}

.overlay  a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  color: var(--nice-fonts);
}


.card:hover .overlay{
  position: absolute;
  display:flex;
  flex-flow: column wrap;
  justify-content: center;
  background: rgba(0, 0, 0, 80%);
  min-height: 100%;
  min-width: 100%;
  font-size: 1.2em;
  font-family: var(--nice-fonts);
  text-align: center;
}

.card > .title{
  max-width: 100%;
  max-height: 100%;
}

.card > .title  h1 {
  font-family: var(--headliners);
  text-transform: uppercase;
  font-size: 1.2em ;
}

.card > .image{
max-width: 100%;
max-height: 100%;
}

.card > .image  img{
  max-width: 100%;
  max-height: 100%;
}

.description{
  font-family: var(--nice-fonts);
  max-width: 100%;
  max-height: 100%;
}


