


.contact_me.form{
  display: flex;
  flex-flow: column;
 justify-content: space-between;
 align-items:center;
}

.contact_me.form > form{
  min-width: 100%;
    max-width:420px;
    margin:50px auto;

}



.contact_me.form > form > ul li{
  flex-flow: column;
  display: flex;
  justify-content:center ;
  column-gap:.5em;
  min-width: 20em;
  min-height: 5em;
  color:white;
  font-family: Helvetica, Arial, sans-serif;
  font-weight:500;
  font-size: 18px;
  border-radius: 5px;
  line-height: 22px;
  background-color: transparent;
  transition: all 0.3s;
  padding: 13px;
  margin-bottom: 15px;
  max-width:50%;
  box-sizing: border-box;
  outline:0;
}




/* textarea */

.contact_me > form input, textarea{
  font: 1em sans-serif;
  width: 100%;
  height:2.0em;
  border-style:none;
  background-color: var(--main-color);
  transition: height .5s ease-in-out ;
  height: 150px;
  line-height: 150%;
  resize:vertical;
}

/* .contact_me.form > form textarea{
  height:10em;
  padding:.5em;

} */

.contact_me > form input:focus, textarea:focus{
  border-color: var(--main-color);
  outline: none;
}


.contact_me > form > ul > li.button > button{
  color:var(--font-color);
  background-color: var(--accent-color);
  border-style: none;
  width:100%;
  border-radius:30px;
  cursor: pointer;
  color:var(--main-color);

  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  width: 100%;
  background:var(--accent-color);
  border-radius:5px;
  border:0;
  cursor:pointer;
  color:white;
  font-size:24px;
  padding-top:10px;
  padding-bottom:10px;
  transition: all 0.3s;
  margin-top:-4px;
  font-weight:700;
}


.contact_me.form > form > ul li:hover{
  box-shadow: inset 0px 0px 5px var(--light-gray);
}

.calendly-badge-widget{
  position: sticky !important;
  float: right;
  /* right:18%; */
}

.calendly-badge-content{
  border-radius: 50% !important;
  width: 95px !important;
  height:95px  !important;
  opacity: 45%;
  background-color: var(--accent-color) !important;

}
/* media query start */
@media screen and (max-width: 800px) {
  .calendly-badge-widget{
    position: sticky !important;
    float: right;
    right:1%;

  }

  .calendly-badge-content{
    position: relative !important;
    border-radius: 50% !important;
    width: 95px !important;
    height:95px  !important;
    opacity: 45%;
  }


}



/* media query end */