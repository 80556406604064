.main_writings{
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 90vw ;
}

.technical.writing.list{
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  min-width:100%;
}

.accordion-item{
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  min-width:100%;
}

.accordion-header{
  display: flex;
  justify-content: center;
  align-items: center;
  border:solid 1px var(--accent-color);
  padding: .5em;
  min-width: 100%;
  border-radius: 30px;
  cursor: pointer;
  font-size: 1.5em;

/* animation on hover code */
/* the first value is the foreground image, second is the background image */
  background-image: linear-gradient(var(--accent-color),var(--accent-color));
  /* horizontal, vertical */
  background-position: 50% 50%;
  background-repeat: no-repeat;

  /* first value: width of the image, second value: height */
  background-size: 0% 100%;
  transition: background-size .5s;
  /* Apply to 2 properties */
  transition: background-size .5s;
}

.accordion-header:hover{
  /* animation on hover code */
  /* first value: width of the image, second value: height */
  background-size: 100% 100%;
}


 .accordion-body{
  display:flex;
  flex-flow: column wrap;
  justify-content: space-between;
  align-items: center;
  max-width: 90vw;
  overflow-x: auto;
  line-height: 2em;
  padding: .5em 1em 1em;
}

/* .accordion-item.active .accordion-header{
  background-color: var(--accent-color);
} */

/* media queries */
@media screen and (min-width:530px) {

  .main_writings{
    width: 80vw ;
  }

}

@media screen and (min-width:600px) {

  .main_writings{
    width: 75vw ;
  }
}
  @media screen and (min-width:900px) {
    .main_writings{
      width: 45vw ;
    }

}
