@keyframes changedrop-shadow {
  from {
    filter: drop-shadow(0 0 .75rem var(--red));
  }
  to {
    filter: drop-shadow(0 0 0 var(--red));
  }
}


.logo > img {
  animation-duration: 5.0s;
  animation-name: changedrop-shadow;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.logo > img{
  display: flex;
  align-items: center;
  gap:1em;
}

.logo > img {
  display: inline-block;
  width:50px;
  height:50px;
  aspect-ratio: auto;
}



