

/* about_me section START */

section.about_me{
display: flex;
flex-flow: column wrap;
align-content: center;
padding: 10%;
gap: .2em 0;
}

section.about_me > h2{
 color: var(--accent-color);
 font-size: 3em;
}

/* about_me section END */



/* about me text with pic  START*/

 div.about_me {
   display: flex;
   justify-content:center ;
   gap:0 .8em;
   max-width: 39em;
   line-height:1.7em;
   font-size: 1.1em;
}

div.about_me >  .content{
  text-align: justify;
  font-family: var(--nice-fonts);
  font-size: .9em;

}

div.about_me > .image_container {
  height: 15em;
  padding-top: .5em;
}

div.about_me > .image_container >img {
    height: 15em;
}

 /* about me text with pic END */


 /* ***********************@media query start ******************************* */


 @media screen and (max-width: 800px) {

  div.about_me {
    display: flex;
    flex-flow: column wrap;
    align-items: flex-end;
    }

    div.about_me > .image_container {
      height: 13em;
      padding-top: .5em;
      align-self: center;


    }

    div.about_me > .image_container >img {
        height: 13em;
    }

  }

 /* ***********************@media query end ******************************* */
