
@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@400;500;600&family=Open+Sans&display=swap');


*{
box-sizing: border-box;
color: var(--font-color);
}

#root{
  /* background-attachment: scroll; */
  background-attachment: fixed;
  background: url("../assets/images/geometric_pattern.svg");
  background-size: 5.3rem;
  background-clip: border-box;
  background-repeat: repeat;
}


 body{
font-weight: 400;
font-style: normal;
font-size: 16px;
word-spacing: 1px;
line-height: 1.15;
font-style: var(--font-color);
background-color: var(--main-color);
-webkit-text-size-adjust: 100%;
-moz-osx-font-smoothing: grayscale;

}


h1{
  font-family: 'Dosis';
  font-style: normal;
  font-weight: 600;
  font-size: 4em;
}

h2{
  font-family: 'Dosis';
  font-style: normal;
  font-weight: 600;
  font-size: 2.5em;
}

h3{
  font-family: 'Dosis';
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
}


a:link{
  text-decoration: none;
}


a:link.active {
  text-decoration: none;
  color: var(--dark-gray);
}


