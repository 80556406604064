

/* footer start */
footer{
  display: flex;
  justify-content: space-around;
  background-color:  var(--main-color);
  padding: 1em 0 ;
  min-width: 100%;
  margin-top: 3rem ;
}

footer > div.social_links{
  display: flex;
  justify-content: center;
  column-gap: 2em;
 }

 footer > p.copyright{
  display: flex;
  align-items: center;
  width:auto;
  font-size: .8em;
  font-family: var(--headliners);
 }






  /* footer end */
