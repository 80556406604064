/* Nav menu start */

.nav-menu{
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  background-color: var(--main-color);
  padding:1.5em 2em 1em;
  width:100%;
}

/* pages on the site */
.site_links{
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap:0 1.5em;

  font-family: 'Dosis';
  font-style: normal;
  font-weight: 600;

}

.list_item>a{
  color:var(--font-color);
}

.list_item:hover a{
 opacity: .5;
}


/* @keyframes changedrop-shadow {
  from {
    filter: drop-shadow(0 0 .5rem var(--red));
  }
  to {
    filter: drop-shadow(0 0 0 var(--red));
  }
} */



.site_links > .list_item > a.active {
  font-size: large;
  color:var(--accent-color);
  pointer-events: none;

  /* animation-duration: 5.0s;
  animation-name: changedrop-shadow;
  animation-iteration-count: infinite;
  animation-direction: alternate; */

}

.nav.logo > .logo-name{
  font-family: 'Dosis';
  font-style: normal;
  font-weight: 600;
  font-size: 3em;
  text-transform: capitalize;
  vertical-align:bottom;
  padding-left: .5em;
  /* font-weight: 900; */
  /* letter-spacing: .1em; */
}


.external_link > a {
  display: flex;
  justify-content: space-around;
}

.external_link > a .feather-external-link{
  align-self: flex-end;
  margin-left: .2em;
  width: 1em;
}

/* Nav menu end */

/* ***********************@media query start ******************************* */

@media screen and (max-width: 800px) {
  .nav-menu{
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: space-between;
    background-color: var(--main-color);
    padding:.9em 0 .5em;
    row-gap: 1em;


  }
}

/* ***********************@media query end ******************************* */