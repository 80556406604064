

div.greeting_screen{
  display:flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  min-height: 100vh;

}



div.greeting_screen > .content > img.logo {
    width:8.5em;
    aspect-ratio: 1/1;
    vertical-align: bottom;
}

div.greeting_screen .greeting_container{
  display: inline-block;
  gap:.5em 0;
  height:auto;
  width: auto;
}



div.greeting_screen .greeting_container > a.call_to_action{
  display: inline-block;
  width: 100%;
  text-align: center;
  padding: .4em;
  color:var(--font-color);
  border-radius: 30px;
  background-color: var(--accent-color);
  font-family: 'Dosis';
  font-style: normal;
  font-weight: 500;
  font-size: 1.3em;
}


/* ***********************@media query start ******************************* */
@media screen and (max-width: 500px) {

  div.greeting_screen{
    display:flex;
    flex-flow: column wrap;
    min-width: 100vw;
  }

  div.greeting_screen > .content{
    display:flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: space-between;
    height: auto;
  }

}


/* ***********************@media query end ******************************* */